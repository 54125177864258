.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.exampleModal {
  max-width: 50rem !important;
  width: 45rem !important;
}

.auto-horizontal-scroll {
  max-width: 100%;
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.auto-horizontal-scroll::-webkit-scrollbar {
  height: 3px;
}

.auto-horizontal-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.auto-horizontal-scroll::-webkit-scrollbar-thumb {
  background: #556ee655;
  border-radius: 10px;
}

.auto-horizontal-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#map_canvas {
  height: 500px;
  position: inherit !important;
}

.image-count-tag {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #1675e0;
  color: #fff;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  text-align: center;
  font-size: 14px;
}

@media(min-width: 900px) {
  #vertical-menu-btn {
    display: none;
  }
}