@import "../../assets/scss/theme.scss";

// .apexcharts-canvas {
//   text {
//     fill: $text-muted !important;
//   }
// }

.text-size{
    overflow: hidden;
    white-space: nowrap;
    max-width: 93px;
    text-overflow: ellipsis;
}
.rev-date-range {
    margin: auto;
    margin-right: 30px;
    font-size: 13pt;
}