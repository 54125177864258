.EcommerceOrderDetails{
    .w-20{
        width: 20% !important;
    }
    .pl-3{
        padding-left: 30px !important;
    }
    .stats-wrapper{
        margin: 2px;
        width: 30%;
    }
    .mt-40{
        margin-top: 40px !important;
    }
    .icon {
        border: 1px solid #ccc; 
        border-radius: 50%; 
        padding: 7px; 
        margin-left: -15px;
        background: #fff;
    }
}