.EcommerceRequestDetails{
    .w-20{
        width: 20% !important;
    }
    .pl-3{
        padding-left: 30px !important;
    }
    .stats-wrapper{
        margin: 2px;
        width: 30%;
    }
    .mt-40{
        margin-top: 40px !important;
    }
    .product_image_container {
        position: relative;
        width: 100%;
        // max-width: 300px;
        overflow: hidden;
    }
    .product_image_container_half {
        position: relative;
        width: 50%;
        // max-width: 300px;
        overflow: hidden;
    }
    .product_image {
        display: block;
        width: 100%;
        height: auto;
    }
    .product_image.sm{
        display: block;
        width: 100%;
        height: 130px;
        object-fit: cover;
    }
    .product_image_overlay {
        position: absolute; 
        bottom: 0; 
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.5); /* Black see-through */
        color: #f1f1f1; 
        width: 100%;
        transition: .5s ease;
        opacity:0;
        color: white;
        font-size: 20px;
        padding: 20px;
        text-align: center;
    }
    .product_image_overlay.sm{
        font-size: 16px;
        padding: 10px;
        width: calc(100% - 25px);
    }
    .product_image_container:hover .product_image_overlay {
        opacity: 1;
    }
    .product_image_container_half:hover .product_image_overlay {
        opacity: 1;
    }
    label {
        cursor: pointer;
        background: #556ee6;
        padding: 8px;
        color: #fff;
        min-width: 100px;
     }
    //  #upload-photo {
    //     opacity: 0;
    //     position: absolute;
    //     z-index: -1;
    //  }
     *[id^='upload-photo-']{
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
}