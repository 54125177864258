.EcommercePromotionDetails{
    .w-20{
        width: 20% !important;
    }
    .pl-3{
        padding-left: 30px !important;
    }
    .stats-wrapper{
        margin: 2px;
        width: 30%;
    }
    .mt-40{
        margin-top: 40px !important;
    }
}