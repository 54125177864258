.payout-timeline {
  margin-left: 20px;
}
.payout-timeline .rs-timeline-item-custom-dot .rs-icon {
  position: absolute;
  background: #fff;
  top: 0;
  left: -2px;
  border: 2px solid #ddd;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 18px;
  padding-top: 9px;
  color: #999;
  margin-left: -13px;
}
.payout-timeline .rs-timeline-item-content {
  margin-left: 24px;
}
.EcommercePayoutsDetailModal{
  .rs-modal-content {
    max-height: 90vh;
    overflow-y: scroll;
    padding-bottom: 50px;
  }
  .payout-orders {
    margin: 30px 0; 
    // display: flex; 
    // justify-content: center
  }
}